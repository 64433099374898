import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { TermsDialogComponent } from './dialog/terms-dialog.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TermsRoutingModule } from './terms-routing.module';
import { TermsComponent } from './terms.component';

@NgModule({
    imports: [
        TermsRoutingModule,
        SharedModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        TermsComponent,
        TermsConditionsComponent,
        TermsDialogComponent,
    ],
    providers: [],
    exports: [
        TermsConditionsComponent,
        TermsDialogComponent,
    ],
    // entryComponents:[TermsConditionsComponent]
})
export class TermsModule {
}
