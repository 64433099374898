import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TermsComponent } from './terms.component';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuardService } from '../shared/services/auth-guard/auth-guard.service';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      canActivate: [AuthGuardService],
      component: LayoutComponent,
      children: [
        {
          path: '',
          component: TermsComponent,
        },
      ],
    },
    // {
    //   path: 'terms-conditions',     
    //   //canActivate: [AuthGuardService],
    //   component: TermsConditionsComponent,
    // },
  ])],
  exports: [RouterModule],
})
export class TermsRoutingModule {
}
