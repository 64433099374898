import { Component, HostBinding, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ITermsConditionsForm } from '../models/terms-conditions-form.interface';
import { ITermsConditions } from '../models/terms-conditions.interface';

@Component({
    selector: 'upsc-terms-dialog',
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.scss'],
})
export class TermsDialogComponent {
    @HostBinding('class') public hostClass = 'terms';

    public formGroup: FormGroup<ITermsConditionsForm>;
    public shouldShowContent = false;
    public contentDelay = 500;

    public constructor(public dialogRef: MatDialogRef<TermsDialogComponent>,
                       @Inject(MAT_DIALOG_DATA) public data: ITermsConditions,
                       private readonly formBuilder: FormBuilder,
    ) {
        this.formGroup = this.formBuilder.group<ITermsConditionsForm>({
            isAgreed: new FormControl(false, Validators.requiredTrue),
        });

        of(this.shouldShowContent)
            .pipe(delay(this.contentDelay))
            .subscribe(
                () => {
                    this.shouldShowContent = true;
                },
            );
    }
}
