<div class="layout">
    <div id="wrapper">
        <div id="page-content-wrapper">
            <div class="header">
                <div class="header-items">
                    <div class="header-item logo-container">
                        <upsc-logo></upsc-logo>
                    </div>
                    <div class="header-item">
                        <button mat-button type="button" class="" (click)="logout()">LOGOUT</button>
                    </div>
                </div>
            </div>

            <div class="ups-page-header">
                <h1 class="page-title">{{ 'acknowledgement' | translate }}</h1>
            </div>
            <div class="terms-conditions">
                <section class="terms-content">{{ 'readAndAccept' | translate }}</section>
                <div class="sub-header terms-content">
                    {{ 'TermsAndConditions' | translate }}
                </div>
                <div class="terms-content content" [innerHTML]="termsConditions.TermsCondition | safe">
                </div>
                <div class="terms-content agree">
                    <mat-checkbox [(ngModel)]="agreedTermsConditions" name="agreedTermsConditions"
                        (ngModelChange)="openPopup()">
                        {{ 'agreeTermsConditions' | translate }}
                    </mat-checkbox>
                </div>
                <div class="sub-header terms-content">
                    {{ 'TermsOfUse' | translate }}
                </div>
                <div class="terms-content content" [innerHTML]="termsConditions.TermsUsage">
                </div>
                <div class="terms-content agree">
                    <mat-checkbox [(ngModel)]="agreedTermsUsage" name="agreedTermsUsage"
                    (ngModelChange)="openPopup()">
                        {{ 'agreeTermsOfUse' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div class="page-actions" *ngIf="!hasCustomFlow">
                <button mat-raised-button class="page-action alternative-button" type="button"
                    (click)="declineTerms()">DECLINE</button>
                <button mat-raised-button class="page-action" type="button" (click)="saveTerms()">ACCEPT &
                    CONTINUE</button>
            </div>
            <div class="page-footer">
                {{'sidebar.footer.links.copyrights' | translate: { currentYear: currentYear} }}
            </div>
        </div>
    </div>
</div>