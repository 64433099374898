<div class="dialog-header">
    <h2 mat-dialog-title>Product Terms and Conditions</h2>
    <button class="close-icon" mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="dialog-content">
    <div class="content" [class.hide]="!shouldShowContent">
        <div class="terms" [innerHTML]="data?.termsConditionsText"></div>
        <form [formGroup]="formGroup">
            <mat-checkbox formControlName="isAgreed">I hereby acknowledge that I have received the above communication and I do not transport
                prohibited/restricted commodities with Parcel Pro as listed above.
            </mat-checkbox>
        </form>
    </div>

    <div class="dialog-actions">
        <button mat-flat-button
                class="dialog-action"
                color="primary"
                type="button"
                [disabled]="!formGroup.valid"
                mat-dialog-close="true">OK
        </button>
    </div>
</div>
