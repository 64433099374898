import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'upsc-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  // TODO: import and declare this component under a module.
  constructor() {
  }

  public ngOnInit() {
  }
}