import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopupComponent } from 'app/account/user-settings/popup/popup.component';
import { User } from 'app/shared/services/user/models/user.model';
import dayjs from 'dayjs';
import { EMPTY, filter, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { AuthenticationService } from '../../core/services/authentication/authentication.service';
import { NotificationType } from '../../shared/models/notification-type';
import { TermsConditions } from '../../shared/models/terms-conditions.model';
import { ErrorHandlerService } from '../../shared/services/error-handler/error-handler.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { UserService } from '../../shared/services/user/user.service';
import { TermsDialogService } from '../dialog/terms-dialog.service';
import { TermsService } from '../services/terms.service';

@Component({
    selector: 'upsc-terms-conditions',
    templateUrl: './terms-conditions.component.html',
    styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent {
    public agreedTermsConditions = false;
    public agreedTermsUsage = false;
    public countryCode: string;
    public hasCustomFlow = false;
    public currentYear = dayjs().format('YYYY');
    private user: User;

    public constructor(private userService: UserService,
                       private notificationService: NotificationService,
                       private router: Router,
                       private authenticationService: AuthenticationService,
                       private errorHandlerService: ErrorHandlerService,
                       private dialog: MatDialog,
                       public termsConditions: TermsConditions,
                       private readonly termsService: TermsService,
                       private readonly termsDialogService: TermsDialogService,
                       public readonly appState: AppState,
    ) {
        this.getTerms();

        const user = this.appState.user$();
        this.countryCode = user.CountryCode;
        this.hasCustomFlow = user.CountryCode === 'IT' ||
            user.CountryCode === 'DE' ||
            user.CountryCode === 'FR' ||
            user.CountryCode === 'GB';
    }

    public getTerms(): void {
        this.userService.getTerms()
            .pipe(
                catchError((err) => {
                    this.notificationService.notify(
                        this.errorHandlerService.getHttpErrorMessage(err),
                        'Error Loading Terms',
                        NotificationType.ERROR);

                    return of(null);
                }),
            )
            .subscribe(
                (res) => {
                    this.termsConditions = res;
                });
    }

    public saveTerms(): void {
        if (this.validateForm()) {
            this.userService.saveTerms('')
                .pipe(
                    catchError((err) => {
                        this.notificationService.notify(
                            this.errorHandlerService.getHttpErrorMessage(err),
                            'Error Accepting Terms',
                            NotificationType.ERROR);

                        return of(null);
                    }),
                    switchMap(() => {
                            return this.userService.getUser()
                                       .pipe(
                                           tap((user) => {
                                               if (user) {
                                                   this.appState.user$.set(user);
                                               }
                                           }),
                                       );
                        },
                    ),
                )
                .subscribe(
                    (user) => {
                        this.checkProductTermsConditions();
                        this.router.navigate(['dashboard']).then();
                    });
        }
    }

    public declineTerms() {
        window.location.href = 'https://parcelpro.com';
    }

    public logout(): void {
        this.authenticationService.logout().then();
    }

    public validateForm(): boolean {
        if (!this.agreedTermsConditions) {
            this.notificationService.notify('You must agree to the Terms and Conditions', 'Validation Error', NotificationType.ERROR);
            return false;
        } else if (!this.agreedTermsUsage) {
            this.notificationService.notify('You must agree to the Terms of Use', 'Validation Error', NotificationType.ERROR);
            return false;
        }
        return true;
    }

    public openPopup() {
        if (this.agreedTermsConditions && this.agreedTermsUsage &&
            (this.countryCode === 'IT' ||
                this.countryCode === 'DE' ||
                this.countryCode === 'FR' ||
                this.countryCode === 'GB')) {
            let hasButtons = true;
            if (this.countryCode === 'DE' || this.countryCode === 'FR' || this.countryCode === 'GB') { hasButtons = false; }
            const dialogConfig: MatDialogConfig = {
                disableClose: true,
                width: '70%',
                data: {
                    stepNumber: 2,
                    termsData: this.termsConditions,
                    hasButtons: hasButtons,
                    countryCode: this.countryCode,
                },
            };

            const dialogRef = this.dialog.open(PopupComponent, dialogConfig);
            dialogRef.afterClosed().subscribe((result) => {
            });
        } else {
            return;
        }
    }

    private checkProductTermsConditions(): void {
        this.termsService.getCustomerProductTermsConditions()
            .pipe(
                catchError((err) => {
                    this.notificationService.notify(
                        err?.Message,
                        'Failed Saving Data',
                        NotificationType.ERROR,
                    );

                    return EMPTY;
                }),
                filter(terms => !!terms?.length),
                switchMap(terms => {
                    return this.termsDialogService.open(terms[0])
                               .pipe(
                                   map(isAccepted => ({ isAccepted, latestTerms: terms[0] })),
                               );
                }),
                filter(res => !!res?.isAccepted),
                map((res) => ({ latestTerms: res.latestTerms })),
                switchMap(res => this.termsService.saveCustomerProductTermsConditions(res?.latestTerms?.productTermsId)),
            )
            .subscribe(
                (res) => {
                    if (res) {
                        return;
                    }

                    this.notificationService.notify(
                        'Failed saving terms acceptance. Please contact administrator.',
                        'Failed Saving Data',
                        NotificationType.ERROR,
                    );
                },
            );
    }
}
