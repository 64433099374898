import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import SafeUrlAssembler from 'safe-url-assembler';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';
import { ITermsConditionsAcceptance } from '../models/terms-conditions-acceptance.interface';
import { ITermsConditions } from '../models/terms-conditions.interface';

@Injectable({ providedIn: 'root' })
export class TermsService {
    public constructor(private readonly http: HttpClient,
                       private readonly apiEndpointService: ApiEndpointService,
    ) {
    }

    public getCustomerProductTermsConditions(): Observable<ITermsConditions[]> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('customerProductTermsConditions'))
            .toString();

        return this.http.get<ITermsConditions[]>(endpoint);
    }

    public saveCustomerProductTermsConditions(productTermsId: string): Observable<ITermsConditionsAcceptance> {
        const endpoint = SafeUrlAssembler()
            .template(this.apiEndpointService.getEndpoint('customerProductTermsConditions'))
            .toString();
        const data = { productTermsId };

        return this.http.post<ITermsConditionsAcceptance>(endpoint, data);
    }
}
