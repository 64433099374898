import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { TermsDialogComponent } from './terms-dialog.component';

@Injectable({ providedIn: 'root' })
export class TermsDialogService<TRequest = unknown, TResponse = unknown> {
    private dialogRef: MatDialogRef<TermsDialogComponent>;

    public constructor(private readonly dialog: MatDialog) {
    }

    public open(data?: TRequest): Observable<TResponse> {
        const config: MatDialogConfig<TRequest> = {
            data,
            disableClose: true,
            maxWidth: '100%',
            width: '39.875rem',
            panelClass: ['mobile-fullscreen-dialog'],
        };

        this.dialogRef = this.dialog.open(TermsDialogComponent, config);
        return this.dialogRef.afterClosed();
    }

    public close(result?: never): void {
        if (!this.dialogRef) {
            return;
        }

        this.dialogRef.close(result);
    }
}
